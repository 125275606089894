<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img :src="require('@/assets/logo.png')" class="center mb-4" height="60" contain></v-img>
        <v-form ref="refForm1" v-model="formValid" lazy-validation>
          <v-card outlined class="pa-6 ma-auto" max-width="720px" elevation="4">
            <h1 class="text-center mb-5">Register</h1>

            <div>
              <v-row>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field label="First Name" v-model="formData.first_name" :error-messages="formErrors.first_name" @input="validate" :rules="formRules.first_name" append-icon="mdi-pencil" required></v-text-field>
                  <v-text-field label="Last Name" v-model="formData.last_name" :error-messages="formErrors.last_name" @input="validate" :rules="formRules.last_name" append-icon="mdi-pencil"></v-text-field>
                  <v-text-field label="Email" v-model="formData.email" :error-messages="formErrors.email" @input="validate" :rules="formRules.email" append-icon="mdi-email" type="email" required></v-text-field>
                  <v-text-field label="Phone" v-model="formData.phone" :error-messages="formErrors.phone" @input="validate" :rules="formRules.phone" append-icon="mdi-phone" required></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-text-field label="Username" v-model="formData.username" :error-messages="formErrors.username" @input="validate" :rules="formRules.username" append-icon="mdi-account" required></v-text-field>
                  <v-text-field label="New Password" v-model="formData.password" :error-messages="formErrors.password" @input="validate" :rules="formRules.password" :type="show1 ? 'text' : 'password'" required :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"></v-text-field>
                  <v-text-field label="Confirm Password" v-model="formData.password_conf" :error-messages="formErrors.password_conf" @input="validate" :rules="formRules.password_conf" :type="show2 ? 'text' : 'password'" required :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-btn class="mr-4 my-2 mt-6" @click="submitForm()" color="primary" block :disabled="!formValid">Register</v-btn>
            <v-btn class="mr-4 my-2" block link :to="{ name: 'Login' }">Back to Login</v-btn>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    show1: false,
    show2: false,
    overlay: false,
    formValid: true,
    formData: {
      username: "",
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      password: "",
      password_conf: "",
    },
    formRules: {
      username: [(v) => !!v || "Name is required"],
      email: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      first_name: [(v) => !!v || "First Name is required"],
      password: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password min 6 characters",
      ],
      password_conf: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password min 6 characters",
      ],
    },
    formErrors: {},
  }),

  computed: {},

  methods: {
    validate() {
      this.formErrors = {}
      this.$refs.refForm1.validate();
    },
    reset() {
      this.$refs.refForm1.reset();
    },
    resetValidation() {
      this.$refs.refForm1.resetValidation();
    },
    submitForm() {
      this.validate();

      if (this.formValid == true) {
        this.showLoadingOverlay(true);

        var formData = new FormData();
        formData.append("username", this.formData.username);
        formData.append("email", this.formData.email);
        formData.append("first_name", this.formData.first_name);
        formData.append("last_name", this.formData.last_name);
        formData.append("phone", this.formData.phone);
        formData.append("password", this.formData.password);
        formData.append("password_conf", this.formData.password_conf);

        this.$axios
          .post("auth/register", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            if (res.data.status == "success") {
              this.showConfirm('', 'Akun berhasil dibuat. Perlu konfirmasi IT.', () => {
                this.$router.push("/login");
              });

            } else {
              this.showAlert(res.data.status, res.data.message);
              this.formErrors = res.data.data.errors;
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      } else {
        this.showAlert("error", "Wew");
      }
    },
  },
};
</script>